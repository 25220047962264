import React, { createElement, useState } from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import {
  Button,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from "swiper/core";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export default function HomePage() {
  return (
    <div>
      <HomeBanner />
      <HighStandardSafe />
      <UniqueSkill />
      <CustomService />
      <PayEasy />
      <Advantage />
      <ContactUs />
    </div>
  );
}

function HomeBanner() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    swiperBox: {
      "& .swiper-pagination": {
        bottom: "3%",
        left: "50%",
        transform: "translateX(-50%)",
      },

      "& .swiper-pagination-bullet": {
        width: "14px",
        height: "14px",
        marginLeft: "1em",
        transition: "all 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
        },
      },
      "& .swiper-button-next:after": {
        fontSize: isTablet ? "20px" : "",
      },
      "& .swiper-button-prev:after": {
        fontSize: isTablet ? "20px" : "",
      },
    },
  });
  const classes = useStyles();
  const slideImages = [
    "/assets/images/Home/banner1.webp",
    "/assets/images/Home/banner2.png",
  ];
  return (
    <div>
      <Swiper
        id="swiper"
        slidesPerView={1}
        autoplay={true}
        loop
        navigation
        pagination={{ clickable: true }}
        className={classes.swiperBox}
      >
        {slideImages.map((item) => {
          return (
            <SwiperSlide>
              <div>
                <img src={item} style={{ width: "100%" }} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

// title component

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles(() => ({
    title: {
      // borderBottom: `5px solid ${theme.palette.primary.main} `,
      paddingBottom: "5px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  }));
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" mb={4}>
      <Typography variant={isTablet ? "h5" : "h4"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

// ANCHOR HighStandardSafe 高標準安全

function HighStandardSafe() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const isPhone = useMediaQuery("(max-width:600px)");
  const safeContent = [
    "3D Secure 2.0 技術有效減少偽冒風險，消費者使用更流暢",
    "全環境通過 PCIDSS Level 1 最高等級安全認證，獨立資安小組與防災應變小組，每季委由美國 Verizon 獨立進行系統稽核，24H 保護您的資料與交易安全",
  ];
  const useStyles = makeStyles({
    listItem: {
      listStyle: "none",
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "0.8em",
        left: isTablet ? "-10px" : "-15px",
        // transform: isTablet ? "translateY(-0.7em)" : "translateY(-50%)",
        width: "5px",
        height: "5px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
      },
    },
  });
  const classes = useStyles();
  return (
    <Box
      py={4}
      minHeight="400px"
      display="flex"
      style={{ backgroundColor: theme.palette.grey[100] }}
      alignItems="center"
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item container direction="column" xs={12} md={6}>
            <Box mr={isTablet ? "" : "auto"}>
              <Title title="高標準安全" />
            </Box>
            <ul>
              {safeContent.map((content) => {
                return (
                  <li className={classes.listItem}>
                    <Typography
                      gutterBottom
                      variant={isTablet ? "body2" : "body1"}
                      style={{ lineHeight: "1.8" }}
                    >
                      {content}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Grid>
          {/* 這邊 */}
          <Grid
            item
            container
            justifyContent={isTablet ? "center" : "flex-end"}
            xs={12}
            md={6}
          >
            <Box mt={1}>
              <img src={"/assets/images/Home/shield.png"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// ANCHOR UniqueSkill 獨家串接技術

function UniqueSkill() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const CustomContent = [
    "代碼化管理信用卡，快速安全交易，自動更新信用卡效期。",
    "管理者友善,一個API可整合6種支付、15家收單銀行、各大國際發卡組織。",
    "一(掃)碼連接各電子錢包;省去尋找軟體麻煩!",
  ];
  const useStyles = makeStyles({
    listItem: {
      listStyle: "none",
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: isTablet ? "1.2em" : "50%",
        left: isTablet ? "-10px" : "-15px",
        transform: isTablet ? "translateY(-0.7em)" : "translateY(-50%)",
        width: "5px",
        height: "5px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
      },
    },
  });
  const classes = useStyles();
  return (
    <Box py={4} minHeight="400px" display="flex" alignItems="center">
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          {/* 這邊 */}
          <Grid
            item
            container
            justifyContent={isTablet ? "center" : ""}
            xs={12}
            md={2}
          >
            <Box>
              <img src={"/assets/images/Home/document.png"} />
            </Box>
          </Grid>

          <Grid
            item
            container
            direction="column"
            alignItems={isTablet ? "center" : "flex-start"}
            xs={12}
            md={8}
            style={{ marginTop: isTablet ? "2em" : "" }}
          >
            <Title title="獨家串接技術" />
            <ul>
              {CustomContent.map((content) => {
                return (
                  <li style={{ marginTop: "1em" }} className={classes.listItem}>
                    <Typography variant={isTablet ? "body2  " : "body1"}>
                      {content}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// ANCHOR CustomService 客製化服務

function CustomService() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const CustomContent = ["專人評估對接方式", "有效快速排除困難"];
  return (
    <Box py={4} minHeight="400px" display="flex" alignItems="center">
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          {/* 這邊 */}
          <Grid
            item
            container
            justifyContent={isTablet ? "center" : ""}
            xs={12}
            md={2}
          >
            <Box>
              <img src={"/assets/images/Home/customize.png"} />
            </Box>
          </Grid>

          <Grid
            item
            container
            direction="column"
            alignItems={isTablet ? "center" : "flex-start"}
            xs={12}
            md={8}
            style={{ marginTop: isTablet ? "2em" : "" }}
          >
            <Title title="客製化服務" />
            {CustomContent.map((content) => {
              return (
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{ lineHeight: "1.8" }}
                >
                  {content}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// ANCHOR PayEasy 付款更方便

function PayEasy() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const payEasyContent = [
    "多樣支付工具提供買方更彈性的選擇。在家也能輕鬆付款。",
    "買家在網頁上購物時，透過 百適匯 付款，即可自由選取支付工具。",
    "以高標準加密技術處理每一筆交易，付款更快速、更方便、更安全。",
  ];
  return (
    <Box
      py={4}
      minHeight="400px"
      display="flex"
      alignItems="center"
      style={{ backgroundColor: theme.palette.grey[100] }}
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item container direction="column" xs={12} md={6}>
            <Box mr={isTablet ? "" : "auto"}>
              <Title title="付款更方便" />
            </Box>
            {payEasyContent.map((content) => {
              return (
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{ lineHeight: "1.8" }}
                >
                  {content}
                </Typography>
              );
            })}
          </Grid>
          {/* 這邊 */}
          <Grid
            item
            container
            justifyContent={isTablet ? "center" : "flex-end"}
            xs={12}
            md={6}
          >
            <Box>
              <img src={"/assets/images/Home/payEasy.png"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// ANCHOR 優勢
function Advantage() {
  const theme = useTheme();
  const isPhone = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    listSvgBox: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      position: "relative",
      marginBottom: "1em",
    },
    listSvg: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
  });
  const classes = useStyles();

  const advantageList = [
    {
      label: "多種支付方式",
      image: "/assets/images/Home/phone.png",
    },
    {
      label: "資料安全保障",
      image: "/assets/images/Home/shieldYellow.png",
    },
    {
      label: "多家銀行簽約合作",
      image: "/assets/images/Home/handShake.png",
    },
    {
      label: "操作簡單不複雜",
      image: "/assets/images/Home/thumbsUp.png",
    },
  ];

  return (
    <Box mt={6} height="400px" display="flex" alignItems="center">
      <Container maxWidth="lg">
        <Box my={6}>
          <Grid container spacing={2}>
            {advantageList.map((item) => {
              return (
                <Grid
                  xs={6}
                  sm={3}
                  container
                  item
                  direction="column"
                  alignItems="center"
                >
                  <Box className={classes.listSvgBox}>
                    {/* <RocketIcon className={classes.listSvg}/> */}
                    <Box className={classes.listSvg}>
                      <img src={item.image} />
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant={isPhone ? "body2" : isTablet ? "body2" : "body2"}
                      style={{ fontWeight: 700 }}
                    >
                      <b>{item.label}</b>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

// ANCHOR 聯繫我們

function ContactUs() {
  const theme = useTheme();
  return (
    <Box my={6} height="400px" display="flex" alignItems="center">
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: theme.palette.primary.main,
              height: "60px",
            }}
          >
            <Typography
              style={{
                color: "#fff",
              }}
            >
              聯繫我們
            </Typography>
          </Button>
          <Box mt={6} textAlign='center'>
            <Typography variant="body1">bespay.service@gmail.com</Typography>
            <Typography variant="body1" >歡迎您隨時與我們聯繫</Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
