import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core'
import TitleIntroduce from './../components/TitleIntroduce'
import { ReactComponent as ATM } from "../svgs/OnlinePaymentPage/atm.svg";
import { ReactComponent as Cellphone } from "../svgs/OnlinePaymentPage/cellphone.svg";
import { ReactComponent as VisitingCard } from "../svgs/OnlinePaymentPage/visitingCard.svg";
import { ReactComponent as Delivery } from "../svgs/OnlinePaymentPage/delivery.svg";

export default function OnlinePaymentPage() {
    const titleData = [
      {
        title: "線上收款",
        subTitle: "一次串接，快速便利",
        content: [
          "整合一個API，在任何的平台或購物車接入支付功能。",
        ],
      },
    ];
    return (
     <Box>
       <TitleIntroduce titleData={titleData}/>
       <ToolsIntroduce/>
       <BenefitContent/>
     </Box>
    );
}

function ToolsIntroduce(){
  const useStyles = makeStyles((theme) => ({
    box:{
      height: '100%',
      minHeight:'330px',
    },
    iconBox:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      marginTop:'15px'
    },
    icon:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width:'64px',
      height:'64px',
      borderRadius:'100%',
      background: theme.palette.secondary.main
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" style={{minHeight:'330px',height:'100%'}}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontWeight: 700,
                margin: "40px 0px",
                textAlign: "center",
              }}
              color="primary"
            >
              提供各種支付工具
            </Typography>
          </Grid>
          <Grid item xs={12} container style={{margin:'30px 0px'}}>
            <Grid item className={classes.iconBox} xs={6} md={3}>
              <Grid className={classes.icon}>
                <VisitingCard />
              </Grid>
              <Grid>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                信用卡
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  xs={6} md={3}>
              <Grid className={classes.icon}>
                <Cellphone />
              </Grid>
              <Grid>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                虛擬帳號(網路銀行)
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  xs={6} md={3}>
              <Grid className={classes.icon}>
                <ATM />
              </Grid>
              <Grid>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  實體ATM(臨櫃)
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  xs={6} md={3}>
              <Grid className={classes.icon}>
                <Delivery />
              </Grid>
              <Grid>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                24小時便利商店
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function BenefitContent() {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height:'330px',
      width: '100%',
      backgroundColor:'#fff'
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.backImg}>
      <Container maxWidth="lg">
        <Grid container style={{ height: "100%",minHeight:'330px'}} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              付款更方便
            </Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: "30px" }}
            >
              多樣支付工具提供買方更彈性的選擇。在家也能輕鬆付款。
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "30px" }}>
              買家在網頁上購物時，透過 揚盛支付 付款，即可自由選取支付工具。
            </Typography>
            <Typography variant="body1" style={{ marginBottom: "30px" }} >
              以高標準加密技術處理每一筆交易，付款更快速、更方便、更安全。
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
