import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function TransactionPage() {
  return (
    <div>
      <TransactionContent />
    </div>
  );
}

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  });
  const classes = useStyles();
  return (
    <Box my={6} display="flex" justifyContent="center">
      <Typography variant={isTablet ? "h5" : "h4"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

function TransactionContent() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const isPhone = useMediaQuery("(max-width:600px)");
  const useStyles = makeStyles({});
  const classes = useStyles();
  const info = [
    {
      title: "消費者權益保障措施及消費糾紛爭議處理原則",
      content: [
        "1.為釐清奇麗科技股份有限公司（以下稱奇麗科技） 奇麗科技會員於使用奇麗科技依電子支付機構管理條例第三條第一項各款所載之各項業務提供服務之過程中，與奇麗科技或收款使用者產生交易糾紛時，奇麗科技應採取之處理方式，特訂定本程序。",
        "2.奇麗科技消費糾紛爭議處理程序，依照金融消費者保護法及消費者保護法、消費者保護法施行細則等法規依據辦理。",
        "3.我方所提供之服務應遵守金融消費者保護法第七條公平、平等與誠信原則:金融服務業與金融消費者訂立契約應本公平合理、平等互惠及誠信原則，其提供金融商品或服務應盡善良管理人之注意義務，以及第八條廣告、業務招攬及營業促銷活動之規範 : 金融服務業從事廣告、業務招攬及營業促銷活動，不得有虛偽、詐欺、隱匿或其他足致他人誤信之情事。及包括第九條適合性原則 : 金融服務業應充分瞭解金融消費者之相關資料，以確保該商品或服務對金融消費者之適合度。第十條，說明義務：金融服務業應說明金融商品、服務、契約之重要內容，並充分揭露風險，以及未盡說明、揭露義務之損害賠償責任。",
        "4.奇麗科技會員於網際網路進行交易時，如與收款使用者涉及商品或服務未獲提供之消費爭議時，應由收款使用者及發行機構負舉證之責。",
        "5.奇麗科技會員對於奇麗科技所提供之金融商品、服務、契約有異議或糾紛時，得聯繫奇麗科技客服專線或至客服信箱進行線上回報，奇麗科技應遵循「金融消費者保護法」及其相關規定，向奇麗科技會員說明並與奇麗科技會員進行糾紛協調。",
        "6.奇麗科技會員以奇麗科技提供的系統進行交易之相關商品或服務，如未獲收款使用者提供、商品或服務瑕疵、商品退貨或服務取消、錯誤溢付款項等事宜，奇麗科技會員應先洽收款使用者尋求解決，奇麗科技會員得聯繫奇麗科技客服專線或至客服信箱進行線上回報，尋求奇麗科技協助。奇麗科技並應提供交易資料並盡力協助解決紛爭。前項提供交易資料之費用，如經查明係屬收款使用者服務之瑕疵，該費用得由奇麗科技向收款使用者收取；反之，則向奇麗科技會員收取。",
        "7.奇麗科技會員以奇麗科技提供的系統向收款使用者進行遞延性商品或服務之交易，發生未收到商品或服務之消費糾紛，並向收款使用者求償無門時，奇麗科技將以交易糾紛爭議程序協助買賣雙方進行協調。",
      ],
    },
    {
      title: "交易糾紛爭議處理說明",
      content: [
        "奇麗科技會員以奇麗科技提供的系統向收款使用者進行一般商品或服務之交易，發生未收到商品或服務之消費糾紛，而奇麗科技會員或收款使用者向奇麗科技提出申訴時，我方有義務盡力協調雙方釐清，並秉持以下原則：",
        "應釐清買賣雙方之訴求內容:",
        "無論買賣雙方透過何種管道進行申訴，我方應先釐清申訴人陳述之事實、訴求之內容是否明確，必要時，應請申訴人補充相關陳述或證據。瞭解買賣雙方申訴之目的後，方足以續行處理。",
        "應查明事實之真相:",
        "我方受理後應積極調閱有關契約及資料、詢問相關人員，以瞭解事實真相，並找出糾紛之癥結所在。",
        "應儘速聯絡當事人:",
        "申訴人係採用線上回報申訴方式，我方於受理後應儘速與申訴人聯絡，並告知我方處理之步驟及時間，以示積極處理之度。",
        "應檢查我方作業程序有無缺失",
        "我方作業過程有無缺失，關係我方過失責任之判斷，應立即查明，此一部分尚包括檢查作業上有無違反法令之處，例如有無 違反金融消費保護及其他保護消費者之法令，若有，應儘量與客戶達成和解並修正錯誤，應為上策。",
        "應諮詢法律相關部門或顧問",
        "應諮詢法務部門或法律顧問，研判法律關係、分析責任歸屬， 並參酌其法律意見處理。",
        "應把握處理時效（申訴案件應追蹤控管）",
        "交易糾紛若以客訴案件處理，無論雙方有無共識，均應於十五個工作天完成完成，並回覆申訴人，若程序複雜確實無法於上述時間內完成者，亦應提前告知客戶。且必要時我方得再延長十五個工作天，以一次為限。倘我方已盡協調之能事或為虛擬產品之交易導致我方難以實際介入判斷或仍無法滿足申訴人之訴求，則須輔導雙方依消費者保護法、消費者保護法施行則進行調處、仲裁或裁判，即後述之各種法律程序。",
      ],
    },

    {
      title: " 各類交易糾紛爭議處理程序",
      content: [
        "收款使用者未履行交易糾紛爭議處理",
        "此交易糾紛係為付款使用者已付款，收款使用者未出貨的狀況之下，所產生的交易爭議。付款使用者可透過奇麗科技線上回覆、致電客服或 Email 至客服信箱等方式進行申訴。",
        "付款使用者提供繳費收據或其相關證明。",
        "奇麗科技確認付款使用者是否已收到貨，釐清其訴求後，將主動協助聯繫收款使用者。接上述，若已聯繫收款使用者，奇麗科技會要求收款使用者提供相關交易資料(如相關出貨證明等)，以利奇麗科技判斷是否為特殊糾紛案件(如三方詐騙)或是一般糾紛案件；若收款使用者未給予回覆(未接聽)，奇麗科技客服將另以電子郵件、語音及簡訊再通知，若超過 24 小時收款使用者未給予回應，奇麗科技會將該筆交易款項暫停撥付措施，以降低付款使用者交易風險。若收款使用者於接獲通知起算 36 小時內未提供資料或相關商品出貨證明，奇麗科技會取消訂單，返還該筆款項給付款使用者。",
        "若經確認為特殊糾紛之三方詐騙案件(即實際付款人並非當初與收款使用者下  單購買的付款使用者)，奇麗科技將主動與對收款使用者聯繫，通知暫停出貨，且  暫停該案付款使用者使用電子支付帳戶相關服務；若收款使用者已經出貨者，  奇麗科技將協助該案收款使用者將相關證據(出貨單等)併同奇麗科技金流相關資料進行  報案。",
        "若為一般糾紛案件，奇麗科技將請收款使用者提供宅配簽收單或其他相關可佐證已收貨之證明。",
        "若付款使用者已提出「暫停撥付｣指示者，在奇麗科技聯絡付款使用者告知商品物流配送狀況，且付款使用者確認無誤後，付款使用者可重新給予支付指示以解除「暫停撥付｣之限制。",
        "若付款使用者仍通知奇麗科技未收到交易物品，奇麗科技將輔導付款使用者可至警政機關進行報案程序。",
      ],
    },
    {
      title: "退換貨糾紛處理",
      content: [
        "退換貨糾紛主要係付款使用者已收到貨物，對於貨物不滿意或是欲退貨收款使用者不願意接收退貨所產生交易糾紛，將依照「交易糾紛處理」作業協調買賣雙方處理。",
        "若使用者間交易產生退、換貨或交易糾紛時，可透過奇麗科技線上回覆、致電客服或 Email 至客服信箱等進件方式進行申訴。",
        "奇麗科技會請付款使用者提供付款證明，請收款使用者提供交易相關資料供奇麗科技處理。若雙方達成協商即可結案；如雙方未達成協商，奇麗科技即輔導雙方可至警政機關進行報案程序。",
      ],
    },
    {
      title: "電子支付帳戶款項移轉糾紛爭議處理",
      content: [
        "如奇麗科技使用者發生電子支付帳戶款項移轉糾紛，收/付款任一方表示轉帳金額有誤，可透過奇麗科技官網、致電客服或 Email 客服信箱等進件方式進行申訴。",
        "奇麗科技調閱相關電磁紀錄，協調雙方處理。經奇麗科技協調雙方進行處理並同意者，使之結案；若雙方不同意奇麗科技處理結果者，奇麗科技將輔導雙方向相關消費申訴機關或法院進行申訴、調解、和解或訴訟。",
      ],
    },
    {
      title: "金流服務糾紛爭議處理說明",
      content: [
        "使用者因使用奇麗科技提供之服務而產生爭議糾紛時，使用者可透過奇麗科技官網、致電客服或 Email 至客服信箱等…方式進行申訴。",
        "使用者向奇麗科技提出申訴時，奇麗科技應於收受申訴之日起三十日內為適當之處理，向權責單位調查此案件並提出改善計畫，將處理結果回覆申訴者。",
        "若申訴者不接受處理結果，得於收受處理結果或期限屆滿之日起六十日內，向爭議處理機構申請評議。",
        "奇麗科技應依據金融消費者保護法辦理，並依據該法之精神，公平合理、迅速有效處理金融消費爭議，使得結案。",
      ],
    },
    {
      title: "使用者帳號/帳戶偽盜冒糾紛爭議處理",
      content: [
        "奇麗科技使用者如發生經奇麗科技實名驗證之帳號、帳戶疑似被盜用之情形時，使用者可透過奇麗科技線上回覆、致電客服或 Email 客服信箱等進件方式進行申訴。",
        "奇麗科技接獲申訴後，與使用者核對身分。",
        "若奇麗科技與使用者核對身分後，經使用者同意，立即該使用者電子支付帳戶之相關服務暫停，待爭議事項釐清(例如確認爭議交易項目內容)，原帳戶使用者可重新設定相關安全措施(例如登入密碼及交易密碼)後開通服務。",
        "若已發生款項遭盜用之情形，奇麗科技將主動先行協助退還該筆款項給使用者。但有下列任一情形者，不在此限：如可證明損失係因使用者之故意或過失所致。",
        "使用者未核對資料或帳單後四十五日內，就資料或帳單內容通知奇麗科技查明；惟使用者有特殊事由(如長途旅行、住院等)致無法通知奇麗科技者，以該特殊事由結束日起算四十五日內需通知奇麗科技查明。",
        "奇麗科技將主動保留帳戶偽盜冒事件之處理所有書面文件及電磁證據，針對該案盜用之狀況，進行後續舉證之調查，確保責任釐清，以防使用者惡意詐騙之行為。必要時奇麗科技將主動提供相關電磁紀錄供警方或 165 反詐騙請求協助，以杜絕類似案件重複發生，並提出預防和改善計劃。",
      ],
    },
    {
      title: "退款作業具體執行之方法",
      content: [
        "法令依據：依電子支付機構業務管理規則第十三條規定，電子支付機構辦理使用者支付款項退款作業時，應將相關款項依使用者原支付方式，退回至其原電子支付帳戶、原存款帳戶或原信用卡帳戶。",
        "電子支付機構經許可經營收受儲值款項業務，除使用者之原支付方式為信用卡刷卡外，得與使用者約定將前項退回款項轉為儲值款項，其儲值餘額仍應符合電子支付機構管理條例第十五條第一項規定。",
        "電子支付機構無法依前二項規定辦理退款作業時，應與使用者約定可供辦理退款作業之使用者本人存款帳戶，將相關款項轉入該存款帳戶，不得以現金支付。",
      ],
    },
  ];

  return (
    <Box>
      <Container maxWidth="lg">
        <Title title="買/賣雙方交易爭議處理機制" />
        <Box bgcolor="#fff" my={2} p={isTablet ? 2 : 4}>
          {info.map((item) => {
            return (
              <>
                <Box my={isTablet ? 2 : 3}>
                  <Typography
                    gutterBottom
                    variant={isTablet ? "h6" : "h4"}
                    style={{ color: "#3facff", fontWeight: 700 }}
                  >
                    {item.title}
                  </Typography>
                </Box>

                <ul
                  style={{
                    listStyle: "none",
                    margin: "1em 0",
                  }}
                >
                  {item.content.map((subItem) => {
                    return (
                      <li style={{ margin: "0.7em" }}>
                        <Typography
                          gutterBottom
                          component="p"
                          variant={isTablet ? "caption" : "body1"}
                        >
                          {subItem}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })}
          <Box style={{display:'flex',justifyContent:'center',margin:'30px 0px'}}>
            <img src='/assets/images/Transaction.jpg' alt='' />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
