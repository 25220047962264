import React from 'react'
import { useForm, Controller } from "react-hook-form";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  InputLabel,
  TextField,
} from '@material-ui/core'

export default function ContactUsPage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #6b3f91',
          margin:'50px auto'
        }
    },
    labeltext: {
      color: "black",
      marginTop: "10px",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
    input:{
      backgroundColor:'#fff'
    }
    }));
  const classes = useStyles();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      fullName: "",
      companyName: "",
      email:"",
      mobile:"",
      subject:"",
      content:""
    },
  });

  function submit(data) {
    console.log(data);
  }

    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={5} md={5}>
          <Grid style={{textAlign:'center'}}>
            <Typography variant="h4">聯絡我們</Typography>
            <Typography variant="body1" style={{ marginBottom: "10px" }}>
              bespay.service@gmail.com
              <br />
              歡迎您隨時與我們聯繫
            </Typography>
          </Grid>
          
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            style={{ padding: "2em 0" }}
          >
            <Grid item xs={10}>
              <InputLabel className={classes.labeltext}>姓名</InputLabel>
              <Controller
                name="fullName"
                control={control}
                rules={{
                  required: "請填寫姓名",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder="必填"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <InputLabel className={classes.labeltext}>公司名稱</InputLabel>
              <Controller
                name="companyName"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder="非必填"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <InputLabel className={classes.labeltext}>聯絡E-mail</InputLabel>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "請填寫E-mail",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder="必填"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <InputLabel className={classes.labeltext}>連絡電話</InputLabel>
              <Controller
                name="mobile"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder="非必填"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <InputLabel className={classes.labeltext}>主旨</InputLabel>
              <Controller
                name="subject"
                control={control}
                rules={{
                  required: "請填寫主旨",
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    placeholder="必填"
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <InputLabel className={classes.labeltext}>意見內容</InputLabel>
              <Controller
                name="content"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    multiline
                    rows={6}
                    rowsMax={8}
                    {...field}
                    error={error}
                    helperText={error && error.message}
                    fullWidth
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={11} style={{ margin: "0px auto 150px",textAlign:'center' }}>
            <Button color="primary" variant="contained" onClick={handleSubmit(submit)}>
              送出
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
}