import React, { createElement, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function ChargePage() {
  return (
    <div>
      <ChargeList />
      <WithDrawStep />
    </div>
  );
}

function Title({ title }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
      borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
  });
  const classes = useStyles();
  return (
    <Box my={6} display="flex" justifyContent="center">
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
}

function SubTitle({ subTitle }) {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    title: {
      fontWeight: "bold",
      paddingBottom: "10px",
    },
  });
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="h6" className={classes.title}>
        {subTitle}
      </Typography>
    </Box>
  );
}

//ANCHOR 收費表
function ChargeList() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    root: {
      "& .MuiTypography-body1": {
        fontSize: isTablet ? "0.6rem" : "",
      },
      "& .MuiTypography-body2": {
        fontSize: isTablet ? "0.6rem" : "",
      },
    },
    table: {
      maxWidth: 1200,
      whiteSpace: "nowrap",
    },
    conditionListUl: {
      overflowX: isTablet ? "scroll" : "",
      overflowY: "0",
      width: isTablet ? "90%" : "70%",
      whiteSpace: "nowrap",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& li p": {
        whiteSpace: "nowrap",
      },
    },
    conditionBox: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff",
    },
    fee: {
      width: "100%",
      height: "100%",
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
    },
    cycle: {
      height: "100%",
      textAlign: "center",
      backgroundColor: theme.palette.grey[300],
    },
    withdrawFee: {
      backgroundColor: "#fff",
      width: "100%",
      height: "100%",
    },
  });
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Title title="收費表" />

      <Box>
        <SubTitle subTitle="交易手續費" />
      </Box>
      {/* list */}
      <Box mb={10}>
        <Grid container className={classes.table}>
          {/* title */}
          <Grid
            container
            item
            xs={12}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              padding: "10px 0",
            }}
          >
            <Grid container item justifyContent="center" xs={2}></Grid>
            <Grid container item justifyContent="center" xs={3}>
              <Typography variant="body1">手續費</Typography>
            </Grid>
            <Grid container item justifyContent="center" xs={4}>
              <Typography variant="body1">限制條件</Typography>
            </Grid>
            <Grid container item justifyContent="center" xs={3}>
              <Typography variant="body1">撥款週期</Typography>
            </Grid>
          </Grid>
          {/* listContent */}
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "10px 0",
            }}
            alignItems="center"
          >
            <Grid item xs={2}>
              <Box
                py={3}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                }}
              >
                <Typography variant="body1" align="center">
                  虛擬帳號
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={3}
              className={classes.fee}
            >
              <Typography variant="body1">20元/筆</Typography>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={4}
              className={classes.conditionBox}
            >
              <ul className={classes.conditionListUl}>
                <li>
                  <Typography variant="body1">交易最低金額30元/筆</Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    交易最高金額30,000元/筆
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.cycle}
              xs={3}
            >
              <Typography variant="body1">
                週撥
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.error.main }}
                >
                  (遇假日順延)
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {/* section2 */}
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "10px 0",
            }}
            alignItems="center"
          >
            <Grid item xs={2}>
              <Box
                py={3}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                }}
              >
                <Typography variant="body1" align="center">
                  信用卡
                  <br />
                  (含紅利)
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={3}
              className={classes.fee}
            >
              <Typography variant="body1">2.8%</Typography>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={4}
              className={classes.conditionBox}
            >
              <ul className={classes.conditionListUl}>
                <li>
                  <Typography variant="body1">每個人交易額度上限:</Typography>
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <Typography variant="body1">
                        個人會員:300,000元
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body1">
                        企業會員:500,000元
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography variant="body1">
                    支援卡別:VISA,Mastercard,JCB
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.cycle}
              xs={3}
            >
              <Typography variant="body1">
                週撥
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.error.main }}
                >
                  (遇假日順延)
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {/* section3 */}
          <Grid
            container
            item
            xs={12}
            style={{
              padding: "10px 0",
            }}
            alignItems="center"
          >
            <Grid item xs={2}>
              <Box
                py={3}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "#fff",
                }}
              >
                <Typography variant="body1" align="center">
                  超商代收
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={3}
              className={classes.fee}
            >
              <Typography variant="body1">30元/筆</Typography>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={4}
              className={classes.conditionBox}
            >
              <ul className={classes.conditionListUl}>
                <li>
                  <Typography variant="body1">每筆訂單上限20,000元</Typography>
                </li>
                <li>
                  <Typography variant="body1">交易最低金額50元/筆</Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className={classes.cycle}
              xs={3}
            >
              <Typography variant="body1">
                週撥
                <Typography
                  variant="body2"
                  style={{ color: theme.palette.error.main }}
                >
                  (遇假日順延)
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {/*section */}
        </Grid>
      </Box>

      {/* 提領手續費 */}
      <Box>
        <SubTitle subTitle="提領手續費" />
      </Box>
      <Box>
        <Grid
          container
          item
          xs={12}
          style={{
            padding: "10px 0",
          }}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Box
              py={3}
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "#fff",
              }}
            >
              <Typography variant="body1" align="center">
                15元/次
              </Typography>
            </Box>
          </Grid>
          <Grid container item style={{ height: "100%" }} xs={10}>
            <Box pl={3} py={3} className={classes.withdrawFee}>
              <Typography variant="body1">每月四次免收手續費優惠</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
//ANCHOR  提領
function WithDrawStep() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles({
    stepBox: {
      padding: "2em 0",
      margin: "2em 0",
      position: "relative",
      "&+&::before": {
        content: '""',
        position: "absolute",
        top: "-3em",
        left: "1em",
        transform: "translate(0%,0%)",
        width: "3px",
        height: "70px",
        backgroundColor: theme.palette.primary.main,
      },
      "&+&::after": {
        content: '""',
        position: "absolute",
        top: "0px",
        left: "11.55px",
        width: "16px",
        height: "16px",
        border: `3px solid ${theme.palette.primary.main}`,
        borderLeft: "0",
        borderTop: "0",
        transform: "rotate(45deg)",
      },
    },
  });
  const classes = useStyles();

  const withdraw = [
    {
      step: 1,
      stepTitle: "登入",
      content: "自「廠商管理後台」登入後，從「我的帳戶」進入設定",
    },
    {
      step: 2,
      stepTitle: "提領",
      content:
        "點選「提領」，進行提領流程（撥款週期為每週，例：商店A於4/1(一)產生一筆交易，揚盛支付 於4/12(五)撥款至商店的「我的帳戶」）",
    },
    {
      step: 3,
      stepTitle: "撥付款項",
      content:
        "申請提領後，款項將於隔一個工作日匯入指定的銀行帳戶內（不含假日及國定假日，例：商店A於4/15(一)當天進行手動提領，則款項將於4/16(二)匯至商店A的實體銀行帳戶）",
    },
  ];
  return (
    <Container maxWidth="lg">
      <Box>
        <Title title="提領流程" />
      </Box>
      {withdraw.map((item) => {
        return (
          <Box display="flex" alignItems="center" className={classes.stepBox}>
            <Typography
              variant="h2"
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bolder",
              }}
            >
              {item.step}
            </Typography>
            <Box ml={isTablet ? 2 : 8}>
              <Typography
                gutterBottom
                variant={isTablet ? "h6" : "h5"}
                style={{ fontWeight: "bold" }}
              >
                {item.stepTitle}
              </Typography>
              <Typography variant={isTablet ? "caption" : "body1"}>
                {item.content}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Container>
  );
}
